import React, { useState, useEffect, useRef } from 'react';
import './HeroContainer.css';
import { useInView } from 'react-intersection-observer';
import Logo from '../logo_full_size.png';
import Footer from '../footer.jpeg';
import { useFrame } from '@react-three/fiber';
import { MeshWobbleMaterial } from '@react-three/drei';

const EnergyBall = () => {
    const meshRef = useRef();

    // Rotate the sphere on every frame
    useFrame(() => {
        if (meshRef.current) {
            meshRef.current.rotation.y += 0.01;
            meshRef.current.rotation.x += 0.005;
        }
    });

    return (
        <mesh ref={meshRef}>
            <sphereGeometry args={[1, 32, 32]} />
            <MeshWobbleMaterial color="#ff5e62" speed={2} factor={0.6} />
        </mesh>
    );
};

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => setScrolled(window.scrollY > 20);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // When a nav link is clicked, close the menu (useful on mobile)
    const handleLinkClick = () => {
        setMenuOpen(false);
    };

    return (
        <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
            <div className="nav-content">
                <div className="logo">
                    <img
                        src={Logo}
                        alt="NEXTEAM Logo"
                        width="180"
                        height="50"
                    />
                </div>
                <div className={`nav-links ${menuOpen ? 'show' : ''}`}>
                    <a href="#home" className="nav-link" onClick={handleLinkClick}>
                        <span>Início</span>
                    </a>
                    <a href="#services" className="nav-link" onClick={handleLinkClick}>
                        <span>Serviços</span>
                    </a>
                    <a href="#projects" className="nav-link" onClick={handleLinkClick}>
                        <span>Projetos</span>
                    </a>
                    <a href="#contact" className="nav-link" onClick={handleLinkClick}>
                        <span>Contato</span>
                    </a>
                </div>
                {/* Hamburger icon visible on mobile */}
                <div
                    className={`hamburger ${menuOpen ? 'open' : ''}`}
                    onClick={() => setMenuOpen(!menuOpen)}
                ></div>
            </div>
        </nav>
    );
};

const ServiceCard = ({ icon, title, description, features }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    return (
        <div ref={ref} className={`service-card ${inView ? 'visible' : ''}`}>
            <div className="service-icon">
                <div className="icon-wrapper">{icon}</div>
            </div>
            <h3 className="service-title">{title}</h3>
            <p className="service-description">{description}</p>
            {features && (
                <ul className="feature-list">
                    {features.map((feature, index) => (
                        <li
                            key={index}
                            className="feature-item"
                            style={{ transitionDelay: `${index * 0.1}s` }}
                        >
                            <span className="arrow-icon">→</span>
                            {feature}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

const Stats = ({ value, label }) => {
    const [count, setCount] = useState(0);
    const [ref, inView] = useInView({ triggerOnce: true });
    const target = parseInt(value);

    useEffect(() => {
        if (inView) {
            const interval = setInterval(() => {
                setCount(prev => {
                    if (prev >= target) {
                        clearInterval(interval);
                        return prev;
                    }
                    return prev + 1;
                });
            }, 50);
            return () => clearInterval(interval);
        }
    }, [inView, target]);

    return (
        <div ref={ref} className="stat-card">
            <div className="stat-value">{value.includes('+') ? value : count}</div>
            <div className="stat-label">{label}</div>
        </div>
    );
};

const AboutUs = () => {
    return (
        <div className="about-page">
            <Navbar />

            {/* Hero Section */}
            <section className="hero-section" id="home">
                <div className="container">
                    <div className="hero-content">
                        <h1 className="hero-title animate-text">
                            Transformando o futuro através da
                            <span>inovação tecnológica</span>
                        </h1>
                        <p className="hero-description animate-text">
                            Soluções integradas em desenvolvimento, energia solar e telecomunicações
                            para impulsionar o crescimento do seu negócio.
                        </p>
                        <div className="hero-buttons">
                            <a href="#services" className="primary-button hover-effect">
                                <span>Nossos Serviços</span>
                            </a>
                            <a href="#contact" className="secondary-button hover-effect">
                                <span>Fale Conosco</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="hero-scroll-indicator">
                    <div className="scroll-downs">
                        <div className="mousey">
                            <div className="scroller"></div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Main Services */}
            <section className="services-section" id="services">
                <div className="container">
                    <div className="section-header">
                        <h2 className="section-title">Nossas Especialidades</h2>
                        <p className="section-description">
                            Unimos expertise técnica com inovação para criar soluções
                            que transformam negócios e impactam o futuro.
                        </p>
                    </div>

                    <div className="main-services">
                        <ServiceCard
                            icon="💻"
                            title="Desenvolvimento Digital"
                            description="Soluções digitais modernas e escaláveis para seu negócio."
                            features={[
                                "Aplicações web responsivas",
                                "Apps móveis multiplataforma",
                                "Sistemas empresariais",
                                "Integrações API"
                            ]}
                        />

                        <ServiceCard
                            icon="☀️"
                            title="Energia Solar"
                            description="Sistemas solares inteligentes com tecnologia de ponta."
                            features={[
                                "Instalação rápida",
                                "Monitoramento IoT",
                                "Otimização ",
                                "Garantia "
                            ]}
                        />

                        <ServiceCard
                            icon="📡"
                            title="Telecomunicações"
                            description="Infraestrutura e serviços de telecomunicações avançados."
                            features={[
                                "Redes de fibra óptica",
                                "Sistemas 5G",
                                "Redes empresariais",
                                "Manutenção"
                            ]}
                        />
                    </div>
                </div>
            </section>

            {/* Showcase Section (Used for Projects in the nav) */}
            <section className="showcase-section" id="projects">
                <div className="container">
                    <div className="showcase-grid">
                        <div className="showcase-content">
                            <h2 className="showcase-title">Tecnologia que Transforma</h2>
                            <p className="showcase-description">
                                Nossa experiência abrange desde infraestrutura de telecomunicações
                                até soluções solares inteligentes, sempre com foco em inovação e
                                eficiência.
                            </p>
                            <div className="tech-grid">
                                <div className="tech-item">⚡ Smart Grids</div>
                                <div className="tech-item">📡 Redes 5G</div>
                                <div className="tech-item">☀️ IoT Solar</div>
                                <div className="tech-item">🔌 Fibra Óptica</div>
                                <div className="tech-item">📶 Redes Mesh</div>
                                <div className="tech-item">🛡️ Segurança</div>
                            </div>
                        </div>
                        <div className="stats-grid">
                            {[
                                { value: "15+", label: "Anos de Experiência" },
                                { value: "100k +", label: "Linhas de Código" },
                                { value: "500km+", label: "Fibra Instalada" },
                                { value: "98 %", label: "Índice Satisfação" }
                            ].map((stat, index) => (
                                <Stats key={index} value={stat.value} label={stat.label} />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact Section */}
            <section className="contact-section" id="contact">
                <div className="container">
                    <div className="contact-grid">
                        <div className="contact-info">
                            <h2 className="contact-title">Vamos Conversar?</h2>
                            <p className="contact-description">
                                Estamos prontos para ajudar sua empresa a alcançar seu potencial máximo
                                através da tecnologia.
                            </p>
                            <div className="contact-details">
                                <a href="mailto:info@nexteam.pt" className="contact-link">
                                    ✉️ info@nexteam.pt
                                </a>
                                <a href="tel:253965288" className="contact-link">
                                    📞 253 965 288
                                </a>
                                <div className="contact-address">
                                    📍 Rua Bombeiro Abilio Nunes Novo nº6, Esposende
                                </div>
                            </div>
                        </div>
                        <form
                            className="contact-form"
                            action="mailto:info@nexteam.pt"
                            method="post"
                            encType="text/plain"
                        >
                            <div className="form-group">
                                <label>Nome</label>
                                <input type="text" name="Nome" required />
                            </div>

                            <div className="form-group">
                                <label>Mensagem</label>
                                <textarea name="Mensagem" rows="4" required></textarea>
                            </div>
                            <button type="submit" className="primary-button">
                                Enviar Mensagem
                            </button>
                        </form>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <footer className="footer">
                <img
                    src={Footer}
                    alt="NEXTEAM Logo"
                    width="500"
                    height="50"
                />
                <div className="container">
                    <p>© 2025 NEXTEAM. Todos os direitos reservados.</p>
                </div>
            </footer>
        </div>
    );
};

export default AboutUs;
